import React, { useCallback, useState } from 'react'
import { useTenants } from '../../context/tenants'
import TenantListItem from './tenantListItem'
import numeral from 'numeral'
import Paging from '../common/paging'
import useKeyPressEffect from '../../hooks/useKeyPressEffect'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const TenantsList = (props) => {
  const {
    onEditTenant,
    onSalesforceAuthLink,
    onHubspotAuthLink,
  } = props

  const { checkPermission } = usePermissions()

  const [arrowIndex, setArrowIndex] = useState(-1)

  const { tenants, page, setPage, pageSize, total } = useTenants()

  const onSetPage = useCallback((currentPage) => {
    setPage(currentPage)
  }, [])

  useKeyPressEffect({
    targetKey: 'ArrowUp',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (arrowIndex > -1) {
        setArrowIndex(arrowIndex - 1)
      }
    }
  }, [arrowIndex])

  useKeyPressEffect({
    targetKey: 'ArrowDown',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (arrowIndex < tenants.length) {
        setArrowIndex(arrowIndex + 1)
      }
    }
  }, [arrowIndex, tenants])

  const onMouseOver = useCallback((i) => {
    setArrowIndex(i)
  }, [])

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            {checkPermission(permissionNames.CanUpdateTenant) && (
              <div className="td-menu" />
            )}
            <div className="td w-48">Name</div>
            <div className="td w-10">Org</div>
            <div className="td w-10">Status</div>
            <div className="td w-10">Region</div>
            <div className="td w-10 text-right" />
          </div>
        </div>
        <div className="tbody">
          {tenants.map((tenant, i) => (
            <TenantListItem
              i={i}
              onMouseOver={onMouseOver}
              selected={i === arrowIndex}
              key={`TenantListItem-${tenant.id}-${i}`}
              tenant={tenant}
              onEditTenant={onEditTenant}
              onSalesforceAuthLink={onSalesforceAuthLink}
              onHubspotAuthLink={onHubspotAuthLink} />
          ))}
        </div>
      </div>
      <Paging
        className="flex w-full items-center justify-between py-3"
        textClassName="text-color-151d49 text-size-15px font-weight-600"
        navigationClassName="flex items-center"
        total={total}
        limit={pageSize}
        page={page}
        onSetPage={onSetPage}
        textFormatter={(start, end, totalPages) => `${numeral(start).format('0,0')} - ${numeral(end).format('0,0')} of ${numeral(totalPages).format('0,0')}`} />
    </>
  )
}

export default TenantsList
