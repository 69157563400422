import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import { toTriggerDataSyncRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import Checkbox from '../common/checkbox'
import { filter } from 'lodash'

const SyncPartsModal = (props) => {
  const {
    modal,
    partsList = [],
    triggerDataSync,
  } = props

  const { open, handleClose } = modal

  const [selectedParts, setSelectedParts] = useState([])

  useEffect(() => {
    if (open) {
      setSelectedParts(partsList.map((p) => p.value))
    }
  }, [open])

  const { tenantId } = useAuth()

  const handleCloseInternal = useCallback(() => {
    handleClose()
  }, [modal])

  const onCheckboxChange = useCallback((e, value) => {
    if (e.target.checked) {
      setSelectedParts([
        ...selectedParts,
        value,
      ])
    } else {
      setSelectedParts([
        ...filter(selectedParts, (v) => v !== value),
      ])
    }
  }, [selectedParts])

  const onSyncNowClicked = useCallback(() => {
    const request = toTriggerDataSyncRequest({
      tenantId,
      partsList: selectedParts,
    })
    triggerDataSync(request)
    handleCloseInternal()
  }, [tenantId, triggerDataSync, selectedParts])

  return (
    <Modal
      maxWidth="sm"
      handleClose={handleCloseInternal}
      open={open}>

      <ModalHeader
        title="Sync Parts"
        onClose={handleCloseInternal} />

      <ModalBody>

        <div className="w-full h-full px-10 pt-10">
          <div className="flex flex-col mt-3 mb-2 px-3 py-2 rounded border border-color-d6d9e6">
            {partsList.map((p) => (
              <div key={`Part-${p.value}`}>
                <Checkbox
                  checked={selectedParts.includes(p.value)}
                  onChange={(e) => onCheckboxChange(e, p.value)}
                  label={p.label}
                  labelProps={{ className: 'text-size-16px text-color-09242f font-weight-400' }} />
              </div>
            ))}
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          size="xs"
          text="Sync Now"
          onClick={onSyncNowClicked} />
      </ModalFooter>

    </Modal>
  )
}

export default SyncPartsModal
