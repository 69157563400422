import { Close, Error } from '@getoutreach/react-icons'
import { DataSyncStatus as SyncStatus, ExportPart, MappingStatus, TenantStatus, OnboardingStep } from '../../grpc/enums'
import { permissionNames } from '../../constants/permissionNames'
import {
  toGetLinkedAccountAuthLinkRequest,
  toSetDataSyncIntervalRequest,
  toTriggerDataSyncRequest,
  toUpdateTenantStatusRequest
} from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useDataSyncInterval } from '../../context/dataSyncInterval'
import { useDataSyncStatus } from '../../context/dataSyncStatus'
import { useGrpcEffect, useGrpcCallback } from '../../grpc'
import { useHistory } from 'react-router-dom'
import { useJoyride } from '../../context/joyride'
import { useModal } from '../../hooks/useModal'
import { useNotification } from '../../hooks/useNotification'
import { useObjectMappings } from '../../context/objectMappings'
import { usePermissions } from '../../context/permissions'
import { useRoutes } from '../../context/routes'
import { useSetup } from '../../context/setup'
import { useTenantInfo } from '../../context/tenantInfo'
import { useUserPrefs } from '../../context/userPrefs'
import Button from '../common/button'
import classNames from 'classnames'
import DataSyncStatus from './dataSyncStatus'
import DataSyncSummary from './dataSyncSummary'
import Header from '../header/header'
import JoyrideModal from '../joyride/joyrideModal'
import LoadingState from '../common/loadingState'
import ObjectMappingListItem from './objectMappingListItem'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SelectList from '../common/selectList'
import Sync from '../icons/sync'
import SyncPartsModal from './syncPartsModal'
import { dataSyncGuide } from '../../constants/externalUrls'
import PageDescription from '../pageDescription/pageDescription'

const partsList = [
  { label: 'Ingest', value: ExportPart.EXPORT_PART_INGEST },
  { label: 'Normalize', value: ExportPart.EXPORT_PART_NORMALIZE },
  { label: 'Transform', value: ExportPart.EXPORT_PART_TRANSFORM },
  { label: 'Index', value: ExportPart.EXPORT_PART_INDEX },
  { label: 'Geyser', value: ExportPart.EXPORT_PART_INDEX_GEYSER },
  { label: 'Signal', value: ExportPart.EXPORT_PART_SIGNAL },
  { label: 'Metric', value: ExportPart.EXPORT_PART_METRIC },
  { label: 'Notify', value: ExportPart.EXPORT_PART_NOTIFY },
  { label: 'Cache', value: ExportPart.EXPORT_PART_CACHE },
]

const dataSyncIntervalOptions = [
  { label: 'Every 15 min', value: 60 * 15 },
  { label: 'Every 30 min', value: 60 * 30 },
  { label: 'Every 45 min', value: 60 * 45 },
  { label: 'Every hour', value: 60 * 60 },
  { label: 'Every 6 hours', value: 60 * 60 * 6 },
  { label: 'Every 12 hours', value: 60 * 60 * 12 },
  { label: 'Every 24 hours', value: 60 * 60 * 24 },
]

const DataSyncMain = (props) => {
  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanReadSync,
      permissionNames.CanUpdateSync,
      permissionNames.CanCreateSync
    )
  }, [checkPermissions])

  const { activeCount, errorCount, inactiveCount, inactiveCountRequired, isFetching, objectMappings } = useObjectMappings()
  const { dataSyncIntervalInSec, setDataSyncIntervalInSec, invalidate: invalidateDataSyncInterval } = useDataSyncInterval()
  const { dataSyncStatus, invalidate: invalidateSyncStatus } = useDataSyncStatus()
  const { enabled: joyrideEnabled, setJoyride, start: startJoyride } = useJoyride()
  const { getPref, savePref } = useUserPrefs()
  const { completeStep, isStepComplete } = useSetup()
  const { notifyError, notifySuccess } = useNotification()
  const { tenantId } = useAuth()
  const { tenantInfo, invalidate: invalidateTenantInfo, isSandbox, crmName } = useTenantInfo()
  const history = useHistory()
  const syncPartsModal = useModal()

  const [syncNowClicked, setSyncNowClicked] = useState(false)
  const [reauthLink, setReauthLink] = useState(null)
  const [hasDismissedDataSyncBanner, setHasDismissedDataSyncBanner] = useState(getPref('joyride', 'hasDismissedDataSyncBanner'))

  const syncIntervalOptions = useMemo(() => {
    return isSandbox ? dataSyncIntervalOptions.slice(-1) : dataSyncIntervalOptions
  }, [isSandbox])

  const allRequiredObjectMappingsAreActive = useMemo(() => {
    // Short circuit until objectMappings are available
    if (!objectMappings.length) {
      return false
    }
    // Find all required Object Mappings
    const required = objectMappings.filter(({ required }) => !!required)
    // Check whether all required Object Mappings are active
    return !!required.every(({ status }) => status === MappingStatus.STATUS_ACTIVE)
  }, [objectMappings])

  const syncNowEnabled = useMemo(() => {
    return !syncNowClicked
      && allRequiredObjectMappingsAreActive
      && dataSyncStatus !== SyncStatus.DATA_SYNC_STATUS_UNSPECIFIED
      && (
        (dataSyncStatus === SyncStatus.DATA_SYNC_STATUS_ACTIVE || dataSyncStatus === SyncStatus.DATA_SYNC_STATUS_ERROR)
        || (objectMappings.length > 0 && objectMappings.length === objectMappings.filter((o) => o.status === MappingStatus.STATUS_ACTIVE).length)
      )
  }, [syncNowClicked, allRequiredObjectMappingsAreActive, dataSyncStatus, objectMappings])

  const enableSyncEnabled = useMemo(() => {
    return allRequiredObjectMappingsAreActive && dataSyncStatus !== SyncStatus.DATA_SYNC_STATUS_NOT_CONFIGURED
  }, [allRequiredObjectMappingsAreActive, dataSyncStatus])

  const configureJoyride = useCallback(() => {
    const steps = []
    // We only want to show this step if the user hasn't seen it
    if (!getPref('joyride', 'hasSeenDataSyncModal')) {
      steps.push({
        target: 'body',
        modalTitle: 'Your CRM connection\nis ready to go',
        title: 'Now let’s review your objects and fields',
        // eslint-disable-next-line max-len
        content: 'To set up the Data Sync page, take a look at all of your objects and their corresponding fields to make sure everything is accurate.\n\nThis step can take a little time, but we’ll guide you through the process — you can always get started and come back later, too.',
        disableBeacon: true,
        locale: { last: 'Get Started', next: 'Get Started' },
        placement: 'center',
        styles: {
          overlay: {
            background: 'rgba(0, 0, 0, 0.3)',
            zIndex: 300
          }
        },
        tooltipComponent: JoyrideModal
      })
      // Update the pref so we don't see this again
      savePref('joyride', 'hasSeenDataSyncModal', true)
    }

    if (activeCount === 1 && !getPref('joyride', 'hasSeenActiveObject')) {
      steps.push({
        target: '#joyride_dataSync_activeCount',
        title: 'Active Object',
        content: 'Your first object is all set and is now listed as active. To finish your data sync, continue configuring your remaining objects.',
        hideProgress: true,
        offset: 0,
        placement: 'right',
      })
      // Update the pref so we don't see this again
      savePref('joyride', 'hasSeenActiveObject', true)
    }

    if (errorCount === 0 && inactiveCountRequired === 0) {
      steps.push({
        target: '#joyride_dataSync_start',
        title: 'Set Sync Schedule',
        content: 'Now that you’ve configured your objects, you can set up how frequently your objects will sync from the CRM. Click Start Sync to complete this step.',
        continuous: false,
        locale: {
          next: 'Got It'
        },
        placement: 'bottom',
        spotlightClicks: true,
      })
    }

    setJoyride(steps)
    startJoyride()
  }, [activeCount, errorCount, inactiveCountRequired, getPref, savePref, setJoyride, startJoyride])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  useGrpcEffect({
    request: toGetLinkedAccountAuthLinkRequest({
      tenantId,
      provider: tenantInfo.crmType
    }),
    onSuccess: ({ authUrl }) => {
      setReauthLink(authUrl)
    },
    grpcMethod: 'getLinkedAccountAuthLink',
    debug: false,
  }, [tenantId, tenantInfo, toGetLinkedAccountAuthLinkRequest])

  const triggerDataSync = useGrpcCallback({
    onError: () => {
      notifyError('Error starting data sync!')
    },
    onSuccess: () => {
      invalidateSyncStatus()
      notifySuccess('Data sync is starting!')
    },
    onFetch: () => setSyncNowClicked(true),
    grpcMethod: 'triggerDataSync',
    debug: false,
  }, [])

  const onSyncNowClicked = useCallback(() => {
    if (permissions.CanAccessInternalAdmin) {
      syncPartsModal.setOpen(true)
    } else {
      const request = toTriggerDataSyncRequest({
        tenantId,
        partsList: partsList.map((p) => (p.value)),
      })
      triggerDataSync(request)
    }
  }, [permissions.CanAccessInternalAdmin, syncPartsModal, tenantId, triggerDataSync])

  const pauseDataSync = useGrpcCallback({
    onError: () => {
      notifyError('Error pausing data sync!')
    },
    onSuccess: () => {
      invalidateTenantInfo()
      notifySuccess('Data sync is paused!')
    },
    grpcMethod: 'updateTenantStatus',
    grpcMethodName: 'pauseDataSync',
    debug: false,
  }, [])

  const enableDataSync = useGrpcCallback({
    onError: () => {
      notifyError('Error enabling data sync!')
    },
    onSuccess: () => {
      invalidateSyncStatus()
      invalidateTenantInfo()
      notifySuccess('Data sync has been enabled!')

      if (!isStepComplete(OnboardingStep.ONBOARDING_STEP_DATA_SYNC)) {
        completeStep(OnboardingStep.ONBOARDING_STEP_DATA_SYNC)
      }
    },
    grpcMethod: 'updateTenantStatus',
    grpcMethodName: 'enableDataSync',
    debug: false,
  }, [completeStep, isStepComplete])

  const onToggleSyncClick = useCallback(() => {
    if (tenantInfo.status !== 'active') {
      const request = toUpdateTenantStatusRequest({
        tenantId,
        newStatus: TenantStatus.STATUS_ACTIVE,
      })
      enableDataSync(request)
    } else {
      const request = toUpdateTenantStatusRequest({
        tenantId,
        newStatus: TenantStatus.STATUS_PENDING,
      })
      pauseDataSync(request)
    }
  }, [tenantId, tenantInfo, pauseDataSync, enableDataSync])

  const setDataSyncInterval = useGrpcCallback({
    onError: () => {
      invalidateDataSyncInterval()
      notifyError('Error setting data sync interval!')
    },
    onSuccess: () => {
      invalidateDataSyncInterval()
      notifySuccess('Data sync interval has been updated!')
    },
    grpcMethod: 'setDataSyncInterval',
    debug: false,
  }, [])

  const onDataSyncIntervalChange = useCallback((option) => {
    if (option) {
      const seconds = option.value
      const request = toSetDataSyncIntervalRequest({
        tenantId,
        baseIntervalSeconds: seconds,
      })
      setDataSyncInterval(request)
      setDataSyncIntervalInSec(seconds)
    }
  }, [setDataSyncInterval, setDataSyncIntervalInSec, tenantId])

  const onDismissDataSyncBanner = useCallback(() => {
    setHasDismissedDataSyncBanner(true)
    savePref('joyride', 'hasDismissedDataSyncBanner', true)
  }, [savePref])

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <Header
          title="Data Sync" />

        <div className="flex-grow overflow-auto">
          <div className="flex flex-col w-full">

            <PageDescription title="Objects in Outreach"
              text={`Listed below are the standard objects in Outreach Commit.
                  ${' '}
                  Each object is configured independently and can be mapped to objects and fields in your CRM. Once configured, the object will show as Active.
                  ${' '}
                  Once all required objects have been made Active, you will be able to begin synchronizing your data by clicking Enable Sync.`}
              link={dataSyncGuide}
              footerText={`If you need to re-authenticate with ${crmName}, you may do so `}
              footerLink={reauthLink}
              footerLinkText="here" />

            {permissions.CanReadSync && (
              <>
                <div className="ml-5 mt-6">

                  <div className="flex items-center justify-between mx-7">
                    <div className="leading-tight">
                      <div
                        onClick={() => history.push(routes.dataSyncHistory)}
                        className="text-color-2e5bff text-size-16px font-weight-500 cursor-pointer">
                        View History
                      </div>
                      <div className="flex items-center">
                        <div className="text-color-09242f text-size-24px font-weight-700">Sync Status:</div>
                        <DataSyncStatus />
                      </div>
                    </div>
                    <div className="flex items-center">
                      {permissions.CanUpdateSync && (
                        <div className="w-full px-2">
                          <SelectList
                            value={dataSyncIntervalInSec}
                            onChange={onDataSyncIntervalChange}
                            options={syncIntervalOptions} />
                        </div>
                      )}
                      {permissions.CanCreateSync && (
                        <button
                          onClick={onSyncNowClicked}
                          className={classNames('flex items-center focus:outline-none', { 'opacity-50 pointer-events-none': !syncNowEnabled })}>
                          <div style={{ transform: 'translateY(2px)' }}>
                            <Sync fill="#818e93" transform="scale(0.65)" />
                          </div>
                          <div className="text-color-818e93 text-size-14px font-weight-400 whitespace-nowrap">sync now</div>
                        </button>
                      )}
                      {permissions.CanUpdateSync && (
                        <Button
                          id="joyride_dataSync_start"
                          text={tenantInfo.status !== 'active' ? 'Start Sync' : 'Pause'}
                          backgroundColor={tenantInfo.status !== 'active' ? undefined : '#fb6c6a'}
                          onClick={onToggleSyncClick}
                          className="mx-4"
                          disabled={!enableSyncEnabled} />
                      )}
                    </div>
                  </div>

                  {isFetching
                    ? (
                      <div className="flex justify-center my-10">
                        <LoadingState
                          header="Loading Objects"
                          subHeader="Please wait..."
                          animate={true} />
                      </div>
                    )
                    : (
                      <>
                        <DataSyncSummary />
                        <div className="flex flex-wrap pl-5 pr-5 py-2">
                          {joyrideEnabled && !hasDismissedDataSyncBanner && inactiveCountRequired > 0 && (
                            <div className="w-full flex items-center justify-between my-4 p-4 bg-color-5951FF text-color-ffffff rounded">
                              <span>
                                <Error className="mr-2" />
                                You must configure and activate the required objects listed below to proceed.
                              </span>
                              <Close className="cursor-pointer focus:outline-none" fontSize="small" onClick={onDismissDataSyncBanner} />
                            </div>
                          )}
                          {objectMappings.map((o, index) => (
                            <ObjectMappingListItem
                              id={`joyride_dataSync_${index}`}
                              key={`ObjectMappingListItem-${o.objectName}-${index}`}
                              activeCount={activeCount}
                              objectMapping={o} />
                          ))}
                        </div>
                      </>
                    )}

                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <SyncPartsModal
        modal={syncPartsModal}
        partsList={partsList}
        triggerDataSync={triggerDataSync} />
    </>
  )
}

export default DataSyncMain
