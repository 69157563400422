import { claimNames } from '../../constants/claimNames'
import { getClaims } from '../../lib/auth0'
import { permissionNames } from '../../constants/permissionNames'
import { toGetTenantByIdRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useGrpcCallback } from '../../grpc'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { useRoutes } from '../../context/routes'
import Button from '../common/button'
import classNames from 'classnames'
import LoadingState from '../common/loadingState'
import React, { useCallback, useMemo, useState } from 'react'
import TenantMenu from './tenantMenu'
import Tooltip from '../common/tooltip'
import useKeyPressEffect from '../../hooks/useKeyPressEffect'

const TenantListItem = (props) => {
  const {
    i,
    selected,
    tenant,
    onEditTenant,
    onMouseOver,
    onSalesforceAuthLink,
    onHubspotAuthLink,
  } = props

  const { id, name, parentTenantId, status, outreachOrg, region } = tenant

  const [parentTenantName, setParentTenantName] = useState('')

  const { baseRoutes } = useRoutes()
  const { checkPermission } = usePermissions()
  const { notifyError } = useNotification()

  const claims = getClaims()

  const { setTenantContext } = useAuth()

  const history = useHistory()

  const isCurrentContext = useMemo(() => {
    return claims[claimNames.tenantId] === id
  }, [claims, id])

  const switchContext = useCallback(({ id: tenantId, name: tenantName, region: tenantRegion }) => {
    if (isCurrentContext) {
      setTenantContext(undefined)
    } else {
      setTenantContext({ id: tenantId, name: tenantName, region: tenantRegion })
    }
    window.location = baseRoutes.default
  }, [isCurrentContext])

  const onMouseOverInternal = useCallback(() => {
    onMouseOver(i)
  }, [onMouseOver, i])

  useKeyPressEffect({
    targetKey: 'Enter',
    eventTarget: ['#searchBox'],
    onDown: () => {
      if (selected) {
        switchContext({ id, name, region })
      }
    }
  }, [id, name, selected, region])

  const getParentTenantName = useGrpcCallback({
    onError: (err) => {
      notifyError('Error fetching Tenant name!')
      setParentTenantName('')
    },
    onSuccess: (obj) => {
      const { tenant: parentTenant } = obj
      setParentTenantName(parentTenant.name)
    },
    grpcMethod: 'getTenantById',
    debug: false,
  }, [])

  const onMouseOverSandbox = useCallback(() => {
    const request = toGetTenantByIdRequest({ tenantId: parentTenantId })
    getParentTenantName(request)
  }, [getParentTenantName, parentTenantId, toGetTenantByIdRequest])

  return (
    <div className={classNames(
      'tr hover:bg-color-51636a-05',
      { 'bg-color-51636a-05': selected }
    )}
      onFocus={onMouseOverInternal}
      onMouseOver={onMouseOverInternal}>

      {checkPermission(permissionNames.CanUpdateTenant) && (
        <div className="td-menu">
          <TenantMenu
            tenant={tenant}
            onEditTenant={onEditTenant}
            onSalesforceAuthLink={onSalesforceAuthLink}
            onHubspotAuthLink={onHubspotAuthLink} />
        </div>
      )}
      <div className="td w-48 leading-tight">
        <div className="flex items-center">
          {name}
          {parentTenantId && (
            <Tooltip
              arrow
              placement="top"
              title={
                <>
                  <span className="font-bold">
                    Parent:
                  </span>
                  {' '}
                  {parentTenantName}
                </> || (
                  <div className="flex items-center">
                    <LoadingState animate dense style={{ width: '20px', height: '20px' }} />
                    <div className="ml-2">Loading</div>
                  </div>
                )
              }
            >
              <div
                className="text-size-12px border border-color-5951FF text-color-5951FF px-2 rounded ml-2"
                {...!parentTenantName && {
                  onFocus: onMouseOverSandbox,
                  onMouseOver: onMouseOverSandbox
                }}
              >
                sandbox
              </div>
            </Tooltip>
          )}
        </div>
        <div className="text-size-11px text-color-a0a8bb">{id}</div>
      </div>
      <div className="td w-10 leading-tight truncate">
        {outreachOrg ? (
          <>
            {outreachOrg.shortname}
            <div className="text-size-11px text-color-a0a8bb truncate">
              {outreachOrg.orgGuid}
              {' '}
              (
              {outreachOrg.bento}
              )
            </div>
          </>
        ) : '--'}
      </div>
      <div className="td w-10">
        <div className="inline-block">
          <div className={classNames('text-size-12px text-color-2e384d border px-2 rounded',
            { 'text-color-1dcf83 border-color-1dcf83': status === 'active' },
            { 'border-color-d8d8d8': status !== 'active' })}>
            {status || '--'}
          </div>
        </div>
      </div>
      <div className="td w-10 leading-tight truncate">
        {region || '--'}
      </div>
      <div className="td w-10 text-right">
        <Button
          size="xs"
          text="Switch Context"
          onClick={() => switchContext({ id, name, region })} />
      </div>
    </div>
  )
}

export default TenantListItem
